// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import type { Node } from '../../types';
import addToMailchimp from 'gatsby-plugin-mailchimp'

type Props = {
  post: Node
};

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date } = post.frontmatter;

  var email = ''

  function _handleChange(e)
  {
    email = e.target.value
  }

  function _handleSubmit(e)
  {
    e.preventDefault();

    console.log('submit', email);

    addToMailchimp(email)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`);

        if (result !== 'success') {
          throw msg;
        }
        alert("Thanks! You're on the list!");
      })
      .catch((err) => {
        console.log('err', err);
        alert(err);
      });
  }

  return (
    <div className={styles['post']}>
      <Link className={styles['post__home-button']} to="/">All Articles</Link>

      <div className={styles['post__content']}>
        <Content body={html} title={title} />
      </div>

      <div className={styles['post__footer']}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author />
      </div>

      <div align="center"><p>Sometimes I send emails:<form onSubmit={_handleSubmit}>
        <input type="email" defaultValue="" placeholder='Email' name="email" id="email" onChange={_handleChange} />
        <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" />
      </form></p>
      </div>

      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
